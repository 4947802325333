<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>常用配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-tabs tab-position="left" style="min-height: 200px;">
                    <el-tab-pane label="关于我们"><douyinEwm></douyinEwm></el-tab-pane>
                    <el-tab-pane label="客服"><wxpayH5></wxpayH5></el-tab-pane>
                    <el-tab-pane label="费用设置"><wxpayApp></wxpayApp></el-tab-pane>
                    <el-tab-pane label="VIP会员设置"><wxpayJsApi></wxpayJsApi></el-tab-pane>
                    <el-tab-pane label="SVIP会员设置"><wxpayMini></wxpayMini></el-tab-pane>
                    <el-tab-pane label="充值打折"><alipayH5></alipayH5></el-tab-pane>
                    <el-tab-pane label="微博链接"><alipayApp></alipayApp></el-tab-pane>
                    <el-tab-pane label="公众号二维码"><alipayPc></alipayPc></el-tab-pane>

                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import wxpayH5 from '@/components/admin/pay/wxpay_h5'
import wxpayApp from '@/components/admin/pay/wxpay_app'
import wxpayJsApi from '@/components/admin/pay/wxpay_jsapi'
import wxpayMini from '@/components/admin/pay/wxpay_mini'
import alipayH5 from '@/components/admin/pay/alipay_h5'
import alipayPc from '@/components/admin/pay/alipay_pc'
import douyinEwm from '@/components/admin/pay/douyin_ewm'
import alipayApp from '@/components/admin/pay/alipay_app'
export default {
    components: {
        wxpayH5,
        wxpayApp,
        wxpayJsApi,
        wxpayMini,
        alipayH5,
        alipayPc,
        alipayApp,
        douyinEwm,
    },
    props: {},
    data() {
      return {
      };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>